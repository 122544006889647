<template>
    <div class="container-fluid">
        <div class="card mt-3 mb-3" style="overflow-x: scroll" ref="table-container">
            <table id="admin_accounts_table" class="display"></table>
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import $ from 'jquery'
import dt from 'datatables.net'
import 'datatables.net-dt/css/jquery.dataTables.css'
import select from 'datatables.net-select'
import 'datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css'
import FixedHeader from 'datatables.net-fixedheader'
import _ from 'lodash'

window.$ = $

window.$.DataTable = dt
window.$.DataTable.select = select
window.$.DataTable.FixedHeader = FixedHeader
const ACCOUNTS_TABLE_UPDATE_INTERVAL = 2000

export default {
    name: 'Accounts',
    i18n: {
        messages: {
            en: {
                order: 'Order',
                accountName: 'Name',
                days: 'Days',
                accountNumber: 'Account',
                brokerCompanyName: 'Company',
                accountCurrency: 'Currency',
                accountLeverage: 'Leverage',
                accountDeposit: 'Deposit',
                accountBalance: 'Balance',
                accountProfit: 'Profit',
                accountEquity: 'Equity',
                accountGain: 'Gain',
                accountGainPerMonthPercent: 'Gain,<br>% pm',
                accountProfitToBalanceRatio: 'Profit/ Balance',
                accountMaxLots: 'Max lots',
                accountMarginLevel: 'Margin Level',
                activity: 'Activity',
                updated: 'Updated',

                profitEURUSD: 'Profit EUR',
                profitUSDCHF: 'Profit CHF',
                profitGBPUSD: 'Profit GBP',
                profitUSDJPY: 'Profit JPY',
                profitTotal: 'Profit All',
                lossEURUSD: 'Loss EUR',
                lossUSDCHF: 'Loss CHF',
                lossGBPUSD: 'Loss GBP',
                lossUSDJPY: 'Loss JPY',
                lossTotal: 'Loss All',
            },
            ru: {
                order: 'Порядок',
                accountName: 'Имя',
                days: 'Дней',
                accountNumber: 'Счет',
                brokerCompanyName: 'Брокер',
                accountCurrency: 'Валюта',
                accountLeverage: 'Плечо',
                accountDeposit: 'Депозит',
                accountBalance: 'Баланс',
                accountProfit: 'Прибыль',
                accountEquity: 'Средства',
                accountGain: 'Прирост',
                accountGainPerMonthPercent: 'Прирост,<br>% вм',
                accountProfitToBalanceRatio: 'Прибыль/ Баланс',
                accountMaxLots: 'Макс лотов',
                accountMarginLevel: 'Уровень Маржи',
                activity: 'Активность',
                updated: 'Обновлен',

                profitEURUSD: 'Прибыль EUR',
                profitUSDCHF: 'Прибыль CHF',
                profitGBPUSD: 'Прибыль GBP',
                profitUSDJPY: 'Прибыль JPY',
                profitTotal: 'Прибыль Все',
                lossEURUSD: 'Убыток EUR',
                lossUSDCHF: 'Убыток CHF',
                lossGBPUSD: 'Убыток GBP',
                lossUSDJPY: 'Убыток JPY',
                lossTotal: 'Убыток Все',
            }
        }
    },
    data: function () {
        return {
            tableColumns: [
                { // Account.license.user user_id
                    mData: 'user_id',
                    sTitle: 'user_id',
                    name: 'user_id',
                    bVisible: false,
                },
                { // Account account_id
                    mData: 'account_id',
                    sTitle: 'account_id',
                    name: 'account_id',
                    bVisible: false,
                },
                { // Sort order
                    mData: 'custom_order',
                    sTitle: this.$t('order'),
                    name: 'custom_order',
                    bVisible: true,
                },
                { // User email
                    mData: 'email',
                    sTitle: 'Email',
                    sClass: 'text-left',
                    name: 'email',
                    bVisible: true,
                },
                { // Account name (email + account number)
                    mData: 'name',
                    sTitle: this.$t('accountName'),
                    name: 'name',
                    bVisible: true,
                },
                { // Days of work (days from license.create_at
                    mData: 'days',
                    sTitle: this.$t('days'),
                    sClass: 'review-column dt-body-center days-table',
                    name: 'days',
                    bVisible: true,
                },
                { // Account number
                    mData: 'number',
                    sTitle: this.$t('accountNumber'),
                    name: 'number',
                    bVisible: true,
                },
                { // Broker company name
                    mData: 'company',
                    sTitle: this.$t('brokerCompanyName'),
                    name: 'company',
                    bVisible: true,
                },
                { // Account currency
                    mData: 'currency',
                    sTitle: this.$t('accountCurrency'),
                    name: 'currency',
                    bVisible: true,
                },
                { // Account leverage
                    mData: 'leverage',
                    sTitle: this.$t('accountLeverage'),
                    name: 'leverage',
                    bVisible: true,
                },
                { // Account deposit
                    mData: 'deposit',
                    sTitle: this.$t('accountDeposit'),
                    name: 'deposit',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                { // Account balance
                    mData: 'balance',
                    sTitle: this.$t('accountBalance'),
                    sClass: 'accounts-table-balance',
                    name: 'balance',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                { // Account balance flag (-1, 0, 1)
                    mData: 'flag_balance',
                    sTitle: 'Flag balance',
                    name: 'flag_balance',
                    bVisible: false,
                },
                { // Account profit
                    mData: 'profit',
                    sTitle: this.$t('accountProfit'),
                    sClass: 'accounts-table-profit',
                    name: 'profit',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                { // Account profit flag (-1, 0, 1)
                    mData: 'flag_profit',
                    sTitle: 'Flag profit',
                    name: 'flag_profit',
                    bVisible: false,
                },
                { // Account equity
                    mData: 'equity',
                    sTitle: this.$t('accountEquity'),
                    sClass: 'accounts-table-equity',
                    name: 'equity',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                { // Account equity flag (-1, 0, 1)
                    mData: 'flag_equity',
                    sTitle: 'Flag equity',
                    sClass: 'flag-equity-table',
                    name: 'flag_equity',
                    bVisible: false,
                },
                { // Gain (balance - deposit)
                    mData: 'gain',
                    sTitle: this.$t('accountGain'),
                    name: 'gain',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                { // Account gain per month in percents
                    mData: 'gain_per_month_percent',
                    sTitle: this.$t('accountGainPerMonthPercent'),
                    name: 'gain_per_month_percent',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                // Buy / Sell lots
                {
                    mData: 'eurusd_lots',
                    sTitle: 'EUR',
                    sClass: 'accounts-table-eurusd-lots',
                    name: 'eurusd_lots',
                    bVisible: true,
                },
                {
                    mData: 'eurusd_lots_flag2',
                    sTitle: 'eurusd_lots_flag2',
                    name: 'eurusd_lots_flag2',
                    bVisible: false,
                },
                {
                    mData: 'usdchf_lots',
                    sTitle: 'CHF',
                    sClass: 'accounts-table-usdchf-lots',
                    name: 'usdchf_lots',
                    bVisible: true,
                },
                {
                    mData: 'usdchf_lots_flag2',
                    sTitle: 'usdchf_lots_flag2',
                    name: 'usdchf_lots_flag2',
                    bVisible: false,
                },
                {
                    mData: 'gbpusd_lots',
                    sTitle: 'GBP',
                    sClass: 'accounts-table-gbpusd-lots',
                    name: 'gbpusd_lots',
                    bVisible: true,
                },
                {
                    mData: 'gbpusd_lots_flag2',
                    sTitle: 'gbpusd_lots_flag2',
                    name: 'gbpusd_lots_flag2',
                    bVisible: false,
                },
                {
                    mData: 'usdjpy_lots',
                    sTitle: 'JPY',
                    sClass: 'accounts-table-usdjpy-lots',
                    name: 'usdjpy_lots',
                    bVisible: true,
                },
                {
                    mData: 'usdjpy_lots_flag2',
                    sTitle: 'usdjpy_lots_flag2',
                    name: 'usdjpy_lots_flag2',
                    bVisible: false,
                },
                // Fixed profit by symbol and total
                {
                    mData: 'fixed_profit_eurusd',
                    sTitle: this.$t('profitEURUSD'),
                    sClass: 'accounts-table-fixed-profit-eurusd',
                    name: 'fixed_profit_eurusd',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'fixed_profit_usdchf',
                    sTitle: this.$t('profitUSDCHF'),
                    sClass: 'accounts-table-fixed-profit-usdchf',
                    name: 'fixed_profit_usdchf',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'fixed_profit_gbpusd',
                    sTitle: this.$t('profitGBPUSD'),
                    sClass: 'accounts-table-fixed-profit-gbpusd',
                    name: 'fixed_profit_gbpusd',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'fixed_profit_usdjpy',
                    sTitle: this.$t('profitUSDJPY'),
                    sClass: 'accounts-table-fixed-profit-usdjpy',
                    name: 'fixed_profit_usdjpy',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'fixed_profit_total',
                    sTitle: this.$t('profitTotal'),
                    sClass: 'accounts-table-fixed-profit-total bold',
                    name: 'fixed_profit_total',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                // Floating profit by symbol and total
                {
                    mData: 'floating_profit_eurusd',
                    sTitle: this.$t('lossEURUSD'),
                    sClass: 'accounts-table-floating-profit-eurusd',
                    name: 'floating_profit_eurusd',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'floating_profit_usdchf',
                    sTitle: this.$t('lossUSDCHF'),
                    sClass: 'accounts-table-floating-profit-usdchf',
                    name: 'floating_profit_usdchf',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'floating_profit_gbpusd',
                    sTitle: this.$t('lossGBPUSD'),
                    sClass: 'accounts-table-floating-profit-gbpusd',
                    name: 'floating_profit_gbpusd',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'floating_profit_usdjpy',
                    sTitle: this.$t('lossUSDJPY'),
                    sClass: 'accounts-table-floating-profit-usdjpy',
                    name: 'floating_profit_usdjpy',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'floating_profit_total',
                    sTitle: this.$t('lossTotal'),
                    sClass: 'accounts-table-floating-profit-total bold',
                    name: 'floating_profit_total',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                { // Profit all + Loss all
                    mData: '',
                    defaultContent: ' ',
                    sTitle: 'ΣAll',
                    sClass: 'accounts-table-profit-all-plus-loss-all-table bold',
                    name: 'fixed_profit_and_floating_profit_all',
                    mRender: $.fn.dataTable.render.number('', '.', 2),
                    bVisible: true,
                },
                { // Profit / Balance ration
                    mData: 'profit_to_balance_ratio',
                    sTitle: this.$t('accountProfitToBalanceRatio'),
                    sClass: 'accounts-table-profit-to-balance-ratio',
                    name: 'profit_to_balance_ratio',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                {
                    mData: 'max_lots_in_one_direction',
                    sTitle: this.$t('accountMaxLots'),
                    sClass: 'accounts-table-max-lots',
                    name: 'max_lots_in_one_direction',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },
                { // Account margin level
                    mData: 'margin_level',
                    sTitle: this.$t('accountMarginLevel'),
                    sClass: 'accounts-table-margin-level',
                    name: 'margin_level',
                    mRender: this.accountsTableNumberFormatter,
                    bVisible: true,
                },

                {
                    mData: 'floating_profit_to_fixed_profit_ratio',
                    sTitle: '<span data-localize="account.floating_profit_to_fixed_profit_ratio">Loss/ Profit</span>',
                    sClass: 'review-column dt-body-center floating_profit_to_fixed_profit_ratio-table',
                    name: 'floating_profit_to_fixed_profit_ratio',
                    mRender: $.fn.dataTable.render.number('', '.', 2),
                    bVisible: false,
                },
                {
                    mData: 'time_since_last_position_was_closed',
                    sTitle: this.$t('activity'),
                    name: 'time_since_last_position_was_closed',
                    bVisible: true,
                    mRender: function (data) {
                        // data in seconds -> result in days
                        return (data / 60 / 60 / 24).toFixed(0)
                    }
                },
                {
                    mData: 'last_update',
                    sTitle: this.$t('updated'),
                    name: 'updated',
                    bVisible: true,
                    mRender: function (data) {
                        return data * -1
                    }
                },
                {
                    mData: 'live_positive_positions_count',
                    sTitle: 'live_positive_positions_count',
                    name: 'live_positive_positions_count',
                    bVisible: false,
                },
                {
                    mData: 'live_positive_positions_in_larger_direction',
                    sTitle: 'live_positive_positions_in_larger_direction',
                    name: 'live_positive_positions_in_larger_direction',
                    bVisible: false,
                },
                {
                    mData: 'live_positive_positions_in_larger_direction_with_specific_profit',
                    sTitle: 'live_positive_positions_in_larger_direction_with_specific_profit',
                    name: 'live_positive_positions_in_larger_direction_with_specific_profit',
                    bVisible: false,
                },
            ],
            accountsTableTimer: null,
            accountsTable: null,
            accountsTableData: [],
            accounts: [],
            accountsTableOrder: [],
            accountsDataTableFixedHeader: null,
        }
    },
    computed: {
        selectedAccount: function () {
            return this.$store.getters.ADMIN_SELECTED_ACCOUNT
        },
        selectedAccounts: function () {
            return this.$store.getters['admin/accountsSettings/selectedAccounts']
        },
        selectedColumns: function () {
            return this.$store.getters['admin/accountsSettings/selectedColumns']
        },
        profitLossOffset: function () {
            return this.$store.getters['admin/accountsSettings/profitLossOffset']
        },
        marginLimit: function () {
            return this.$store.getters['admin/accountsSettings/marginLimit']
        },
        maxProfitBalanceRatio: function () {
            return this.$store.getters['admin/accountsSettings/maxProfitBalanceRatio']
        },
        maxLots: function () {
            return this.$store.getters['admin/accountsSettings/maxLots']
        }
    },
    watch: {
        selectedAccounts: function () {
            this.reloadAccountsTableData()
        },
        selectedColumns: function () {
            this.applyTableColumnsFilter()
        },
        profitLossOffset: function () {
            this.reloadAccountsTableData()
        },
        marginLimit: function () {
            this.accountsTable.draw()
        },
        maxLots: function () {
            this.accountsTable.draw()
        },
        maxProfitBalanceRatio: function () {
            this.accountsTable.draw()
        },
    },
    methods: {
        // Table refreshing
        startAccountsTableDataReloading: function () {
            if (!this.accountsTableTimer) {
                this.reloadAccountsTableData()
                this.accountsTableTimer = setInterval(this.reloadAccountsTableData, ACCOUNTS_TABLE_UPDATE_INTERVAL)
            }
        },
        stopAccountsTableDataReloading: function () {
            clearInterval(this.accountsTableTimer)
            this.accountsTableTimer = null
        },
        reloadAccountsTableData: function () {
            axios.get('/admin/accounts-table', {
                params: {
                    pr_ls_offset: this.profitLossOffset,
                    a_ids: this.selectedAccounts.join(','),
                    // TODO: implement
                    //columns: this.selectedColumns.join(','),
                },
            })
                .then(response => {
                    this.accountsTableData = response.data
                    this.accountsTable.clear()
                    this.accountsTable.rows.add(this.accountsTableData).draw()
                })
        },
        accountsTableNumberFormatter: function (data) {
            if (data === 0) {
                return ''
            }
            return data.toFixed(2)
        },
        applyTableColumnsFilter(visible) { // [ indexes ]
            if (visible) {
                return
            }

            setTimeout(() => {
                this.accountsTable.columns().visible(false, false)
                this.accountsTable.columns(this.selectedColumns.map(column => column + ':name')).visible(true, false)
                this.accountsTable.columns.adjust().draw(false)
            })
        },
        // Table columns order
        initializeAccountsTableOrder: function () {
            this.accountsTableOrder = []
            try {
                let order = JSON.parse(window.localStorage.getItem('admin-accounts-table-order'))
                if (order != null) {
                    // Применение сортировки
                    this.accountsTableOrder = order
                }
            } catch (e) {
                console.log('Не удалось загрузить локально сохраненную сортировку')
            }
        },
        handleAccountsTableOrderChange: function (newOrder) {
            // Сохранение в localStorage
            window.localStorage.setItem('admin-accounts-table-order', JSON.stringify(newOrder))
            this.accountsTableOrder = newOrder
        }
    },
    async mounted() {
        const self = this

        // Set available table columns
        this.$store.commit('admin/accountsSettings/setColumnsOptions', this.tableColumns
            .filter(column => column.bVisible)
            .map(column => {
                return {
                    label: column.sTitle.replace(/(<([^>]+)>)/gi, ""), // Strip HTML Tags
                    value: column.name,
                }
            }))

        // Load sort order
        this.initializeAccountsTableOrder()

        this.accountsTable = $('#admin_accounts_table').DataTable({
            rowReorder: {
                enable: false,
                selector: '.custom-order-table',
                update: false,
                dataSrc: 'custom_order',
            },
            deferRender: true,
            select: {style: 'single'},  //выделение строки при клике
            dom: 'Bfrtip', // https://datatables.net/reference/option/dom
            buttons: [
                // TODO: print button
            ],
            paging: false,
            searching: false,
            info: false,
            responsive: false,
            columns: this.tableColumns,
            scrollCollapse: true,
            order: self.accountsTableOrder,
            rowCallback: function (nRow, aData) {
                // Default row color
                $(nRow).css('background-color', 'white')

                // Background color for profitable positions
                if (aData['live_positive_positions_count'] > 0) {
                    $(nRow).css('background-color', '#E4FFF6')
                }
                // Background color for profitable positions in larger direction
                if (aData['live_positive_positions_in_larger_direction'] > 0) {
                    $(nRow).css('background-color', '#D0FDEA')
                }
                // Background color for profitable positions in larger direction with specific profit
                if (aData['live_positive_positions_in_larger_direction_with_specific_profit'] > 0) {
                    $(nRow).css('background-color', '#8AFFCD')
                }

                // Margin limit check
                if (aData['margin_level'] < self.marginLimit) {
                    $('.accounts-table-margin-level', nRow).css('color', '#FF0000')
                }
                if (aData['margin_level'] > self.marginLimit) {
                    $('.accounts-table-margin-level', nRow).css('color', '#00008B')
                }

                // Max lots in one direction color
                if(aData['max_lots_in_one_direction'] > self.maxLots) {
                    $('.accounts-table-max-lots', nRow).css('color', '#FF0000');
                } else {
                    $('.accounts-table-max-lots', nRow).css('color', '#000000');
                }

                // Balance color
                if (aData['flag_balance'] === 1) {
                    $('.accounts-table-balance', nRow).css('color', '#00008B')
                }
                if (aData['flag_balance'] === 0) {
                    $('.accounts-table-balance', nRow).css('color', '#000000')
                }
                if (aData['flag_balance'] === -1) {
                    $('.accounts-table-balance', nRow).css('color', '#FF0000')
                }

                // Profit color
                if (aData['flag_profit'] === 1) {
                    $('.accounts-table-profit', nRow).css('color', '#00008B')
                }
                if (aData['flag_profit'] === 0) {
                    $('.accounts-table-profit', nRow).css('color', '#000000')
                }
                if (aData['flag_profit'] === -1) {
                    $('.accounts-table-profit', nRow).css('color', '#FF0000')
                }

                // Equity color
                if (aData['flag_equity'] === 1) {
                    $('.accounts-table-equity', nRow).css('color', '#00008B')
                }
                if (aData['flag_equity'] === 0) {
                    $('.accounts-table-equity', nRow).css('color', '#000000')
                }
                if (aData['flag_equity'] === -1) {
                    $('.accounts-table-equity', nRow).css('color', '#FF0000')
                }

                // Profit / balance color
                if (Math.abs(aData['profit_to_balance_ratio']) > self.maxProfitBalanceRatio) {
                    $('.accounts-table-profit-to-balance-ratio', nRow).css('color', '#FF0000')
                } else {
                    $('.accounts-table-profit-to-balance-ratio', nRow).css('color', '#00008B')
                }

                // Fixed profit colors
                if (aData['fixed_profit_total'] <= 0) {
                    $('.accounts-table-fixed-profit-total', nRow).css('color', '#FF0000')
                }
                if (aData['fixed_profit_total'] > 0) {
                    $('.accounts-table-fixed-profit-total', nRow).css('color', '#00008B')
                }
                if (aData['fixed_profit_eurusd'] <= 0) {
                    $('.accounts-table-fixed-profit-eurusd', nRow).css('color', '#FF0000')
                }
                if (aData['fixed_profit_eurusd'] > 0) {
                    $('.accounts-table-fixed-profit-eurusd', nRow).css('color', '#00008B')
                }
                if (aData['fixed_profit_usdchf'] <= 0) {
                    $('.accounts-table-fixed-profit-usdchf', nRow).css('color', '#FF0000')
                }
                if (aData['fixed_profit_usdchf'] > 0) {
                    $('.accounts-table-fixed-profit-usdchf', nRow).css('color', '#00008B')
                }
                if (aData['fixed_profit_gbpusd'] <= 0) {
                    $('.accounts-table-fixed-profit-gbpusd', nRow).css('color', '#FF0000')
                }
                if (aData['fixed_profit_gbpusd'] > 0) {
                    $('.accounts-table-fixed-profit-gbpusd', nRow).css('color', '#00008B')
                }
                if (aData['fixed_profit_usdjpy'] <= 0) {
                    $('.accounts-table-fixed-profit-usdjpy', nRow).css('color', '#FF0000')
                }
                if (aData['fixed_profit_usdjpy'] > 0) {
                    $('.accounts-table-fixed-profit-usdjpy', nRow).css('color', '#00008B')
                }

                // Floating profit colors
                if (aData['floating_profit_eurusd'] <= 0) {
                    $('.accounts-table-floating-profit-eurusd', nRow).css('color', '#FF0000')
                }
                if (aData['floating_profit_eurusd'] > 0) {
                    $('.accounts-table-floating-profit-eurusd', nRow).css('color', '#00008B')
                }
                if (aData['floating_profit_usdchf'] <= 0) {
                    $('.accounts-table-floating-profit-usdchf', nRow).css('color', '#FF0000')
                }
                if (aData['floating_profit_usdchf'] > 0) {
                    $('.accounts-table-floating-profit-usdchf', nRow).css('color', '#00008B')
                }

                if (aData['floating_profit_gbpusd'] <= 0) {
                    $('.accounts-table-floating-profit-gbpusd', nRow).css('color', '#FF0000')
                }
                if (aData['floating_profit_gbpusd'] > 0) {
                    $('.accounts-table-floating-profit-gbpusd', nRow).css('color', '#00008B')
                }
                if (aData['floating_profit_usdjpy'] <= 0) {
                    $('.accounts-table-floating-profit-usdjpy', nRow).css('color', '#FF0000')
                }
                if (aData['floating_profit_usdjpy'] > 0) {
                    $('.accounts-table-floating-profit-usdjpy', nRow).css('color', '#00008B')
                }
                if (aData['floating_profit_total'] <= 0) {
                    $('.accounts-table-floating-profit-total', nRow).css('color', '#FF0000')
                }
                if (aData['floating_profit_total'] > 0) {
                    $('.accounts-table-floating-profit-total', nRow).css('color', '#00008B')
                }

                // Profit all + Loss all calculation and color
                let profitLossSum = aData['fixed_profit_total'] + aData['floating_profit_total']
                if (typeof profitLossSum === 'number' && profitLossSum !== 0) {
                    $('.accounts-table-profit-all-plus-loss-all-table', nRow).text(profitLossSum.toFixed(2))
                    $('.accounts-table-profit-all-plus-loss-all-table', nRow).css('color', profitLossSum > 0 ? '#00008B' : '#FF0000')
                }

                if (Math.abs(aData['floating_profit_to_fixed_profit_ratio']) > 1) {
                    $('.floating_profit_to_fixed_profit_ratio-table', nRow).css('color', '#FF0000')
                } else {
                    $('.floating_profit_to_fixed_profit_ratio-table', nRow).css('color', '#00008B')
                }
                // -2 -1 0 1 2
                const weekUnidirectionalColor = '#A4D3FD'
                const strongUnidirectionalColor = '#65B6FF'
                const weekOppositeColor = '#FFC7DF'
                const strongOppositeColor = '#FF8FBF'

                // Lots color
                if (aData['eurusd_lots_flag2'] === 1) {
                    $('.accounts-table-eurusd-lots', nRow).css('background', weekUnidirectionalColor)
                }
                if (aData['eurusd_lots_flag2'] === 2) {
                    $('.accounts-table-eurusd-lots', nRow).css('background', strongUnidirectionalColor)
                }
                if (aData['eurusd_lots_flag2'] === -1) {
                    $('.accounts-table-eurusd-lots', nRow).css('background', weekOppositeColor)
                }
                if (aData['eurusd_lots_flag2'] === -2) {
                    $('.accounts-table-eurusd-lots', nRow).css('background', strongOppositeColor)
                }

                if (aData['usdchf_lots_flag2'] === 1) {
                    $('.accounts-table-usdchf-lots', nRow).css('background', weekUnidirectionalColor)
                }
                if (aData['usdchf_lots_flag2'] === 2) {
                    $('.accounts-table-usdchf-lots', nRow).css('background', strongUnidirectionalColor)
                }
                if (aData['usdchf_lots_flag2'] === -1) {
                    $('.accounts-table-usdchf-lots', nRow).css('background', weekOppositeColor)
                }
                if (aData['usdchf_lots_flag2'] === -2) {
                    $('.accounts-table-usdchf-lots', nRow).css('background', strongOppositeColor)
                }

                if (aData['gbpusd_lots_flag2'] === 1) {
                    $('.accounts-table-gbpusd-lots', nRow).css('background', weekUnidirectionalColor)
                }
                if (aData['gbpusd_lots_flag2'] === 2) {
                    $('.accounts-table-gbpusd-lots', nRow).css('background', strongUnidirectionalColor)
                }
                if (aData['gbpusd_lots_flag2'] === -1) {
                    $('.accounts-table-gbpusd-lots', nRow).css('background', weekOppositeColor)
                }
                if (aData['gbpusd_lots_flag2'] === -2) {
                    $('.accounts-table-gbpusd-lots', nRow).css('background', strongOppositeColor)
                }

                if (aData['usdjpy_lots_flag2'] === 1) {
                    $('.accounts-table-usdjpy-lots', nRow).css('background', weekUnidirectionalColor)
                }
                if (aData['usdjpy_lots_flag2'] === 2) {
                    $('.accounts-table-usdjpy-lots', nRow).css('background', strongUnidirectionalColor)
                }
                if (aData['usdjpy_lots_flag2'] === -1) {
                    $('.accounts-table-usdjpy-lots', nRow).css('background', weekOppositeColor)
                }
                if (aData['usdjpy_lots_flag2'] === -2) {
                    $('.accounts-table-usdjpy-lots', nRow).css('background', strongOppositeColor)
                }

                return nRow
            },
            drawCallback: function () {
                const api = this.api()
                // Проверка выбора строки
                if (!self.selectedAccount) {
                    if (api.rows().count() > 0) {
                        api.row(0).select()
                        self.$store.commit('SET_ADMIN_SELECTED_ACCOUNT', api.row(0).data())
                    }
                } else {
                    for (let i = 0; i < api.rows().count(); i++) {
                        if (api.row(i).data()['account_id'] === self.selectedAccount['account_id']) {
                            api.rows().deselect()
                            api.row(i).select()
                            return
                        }
                    }
                }
            },
            userSelect: function () {
                console.log('user Select')
            }
        })
        this.accountsDataTableFixedHeader = new $.fn.dataTable.FixedHeader(this.accountsTable, {
            headerOffset: 167,
        })

        // Start reloading table data
        this.startAccountsTableDataReloading()

        // При выборе строки в таблице accounts
        this.accountsTable.on('user-select', function (e, dt, type, cell) {
            const rowIndex = cell.index().row
            const row = dt.row(rowIndex).node()
            if ($(row).hasClass('selected')) {
                // deselect
                e.preventDefault()
                return
            }
            self.$store.commit('SET_ADMIN_SELECTED_ACCOUNT', dt.row(rowIndex).data())
        })

        // При изменении сортировки
        this.accountsTable.on('order', function (e, settings, ordArr) {
            if (ordArr.length > 0) {
                self.handleAccountsTableOrderChange([[ordArr[0].col, ordArr[0].dir]])
            }
        })

        // On cell double click
        this.accountsTable.on('dblclick', 'td', function () {
            // Identify cell that was clicked
            const cellIndex = self.accountsTable.cell(this).index() // { column, columnVisible, row }
            // const rowData = self.accountsTable.row( this ).data()
            const fieldName = self.tableColumns[cellIndex.column].mData

            switch (fieldName) {
                case 'name':
                    self.$router.push('/admin/administration/charts')
                    break
                default:
                    self.$router.push('/admin/administration/settings')
            }
        })

        this.$refs['table-container'].addEventListener('scroll', _.debounce(() => {
            this.accountsTable.fixedHeader.adjust()
        }, 50))
    },
    beforeDestroy() {
        this.stopAccountsTableDataReloading()
        this.accountsDataTableFixedHeader.destroy()
        this.accountsTable.destroy()
    }
}
</script>

<style scoped>
@import '../../../../styles/datatable.scss';

#admin_accounts_table {
    width: min-content !important;
}

table >>> .dataTable tbody tr.selected {
    background-color: #b0bed9 !important;
}

table >>> tbody tr {
    cursor: pointer;
    text-align: center;
}

table >>> tbody tr td {
    /*width: 20px;*/
    padding: 8px 1px;
}

table >>> thead tr th {
    border: none;
}

table >>> .bold {
    font-weight: 600;
}

table.dataTable {
    margin: 0 auto 0 0 !important;
}

</style>
